var render = function () {
  var _vm$initialMtpData$mt, _vm$mtpData$proposedF, _vm$mtpData$proposedF2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userPermissions.includes('BudgetRequestDetailsView') ? _c('div', {
    staticClass: "budget-request details-page"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _vm.data.changedMtpLoading === true || _vm.data.removeFromMtp === true || _vm.data.deleteBr === true || _vm.data.exportBr === true ? _c('loading-spinner') : _vm._e(), _c('b-toast', {
    attrs: {
      "id": "toast-exception",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1), _c('div', [_c('span', [_vm._v("Something bad happened.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "funding-status-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center funding-status-toast"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.data.constants && _vm.mtpData ? _c('span', [_vm._v("Funding status updated to \"" + _vm._s(_vm.data.constants.fundingStatus[_vm.mtpData.fundingStatus]) + "\". ")]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "project-status-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center project-status-toast"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.data.constants && _vm.mtpData ? _c('span', [_vm._v("Project status updated to \"" + _vm._s(_vm.data.constants.projectStatusType[_vm.mtpData.projectStatus]) + "\". ")]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "edit-controlling-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.data.constants && _vm.mtpData ? _c('span', [_vm._v("The data has been updated.")]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('mtp-status-flagged-modal', {
    on: {
      "clickedupdateflagged": _vm.onClickCancel
    }
  }), _c('mtp-status-reviewed-modal', {
    on: {
      "clicked": _vm.onClickCancel
    }
  }), _c('state-edit-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  }), _c('confirmation-review-modal'), _c('set-inactive-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  }), _c('update-mtp-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  }), _c('confirm-delete-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel,
      "deletebudget": _vm.deleteBrInactive
    }
  }), _c('remove-br-modal', {
    ref: "remove-br-modal",
    attrs: {
      "created-year": _vm.createdYear
    },
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  }), _c('move-current-mtp-warning-modal', {
    attrs: {
      "budget-request-id": _vm.budgetRequestId,
      "selected-mtp-year": _vm.mtpYear
    },
    on: {
      "clickedcanceleditstate": _vm.onClickCancel,
      "reInitMtp": _vm.reInitMtp
    }
  }), _vm.selectedMtpStatus && (_vm.userPermissions.includes('BudgetRequestReviewEdit') || _vm.userPermissions.includes('BudgetRequestDetailsEdit')) ? _c('edit-controlling-modal', {
    key: _vm.selectedMtpStatus.id,
    ref: "edit-controlling",
    on: {
      "edit-controlling-information": _vm.getDataEditControlling
    }
  }) : _vm._e(), _vm.data.navBarRefresh === false ? _c('div', {
    staticClass: "container-fluid pt-3 pl-xl-5"
  }, [_vm.detailsBudgetRequests && _vm.data && _vm.mtpData ? _c('div', {
    staticClass: "row justify-content-center mb-4"
  }, [_c('div', {
    staticClass: "col-xl-10 order-1 breadcrumbitem pb-lg-4 pb-0"
  }, [_vm.detailsBudgetRequests.isActive ? _c('p', [_c('span', {
    staticClass: "mtp-color pointerLink",
    on: {
      "click": function ($event) {
        return _vm.goToBrMtpYear(_vm.mtpData.year);
      }
    }
  }, [_vm._v("MTP " + _vm._s(_vm.mtpData.year) + " ")]), _c('icon', {
    staticClass: "icon-right",
    attrs: {
      "name": "chevron-right"
    }
  }), _vm._v(" " + _vm._s(_vm.detailsBudgetRequests.globalId) + " ")], 1) : _vm._e(), !_vm.detailsBudgetRequests.isActive ? _c('p', [_c('span', {
    staticClass: "mtp-color pointerLink",
    on: {
      "click": function ($event) {
        return _vm.goToBrMtpYear(0);
      }
    }
  }, [_vm._v("Inactive Budget Requests ")]), _c('icon', {
    staticClass: "icon-right",
    attrs: {
      "name": "chevron-right"
    }
  }), _vm._v(" " + _vm._s(_vm.detailsBudgetRequests.globalId) + " ")], 1) : _vm._e()]), _c('div', {
    staticClass: "col-xl-6 col-lg-7 order-lg-2 order-3 section-first"
  }, [_vm.showWarning ? _c('h5', {
    staticClass: "warning-label pb-3"
  }, [_vm._v(" Following fields have different values in MSP or PTRisk: " + _vm._s(_vm.mappingWarning) + ". Please see highlighted fields below. ")]) : _vm._e(), _vm.noValidationWarning ? _c('h5', {
    staticClass: "warning-label"
  }, [_vm._v(" External data validation is not available when multiple extrenal items are linked to the same budget request! ")]) : _vm._e(), _vm.detailsBudgetRequests ? _c('h2', {
    staticClass: "pb-3"
  }, [_vm._v(" " + _vm._s(_vm.detailsBudgetRequests.name) + " ")]) : _vm._e(), _c('multiselect', {
    attrs: {
      "allow-empty": false,
      "disabled": !_vm.isCurrentMtp || (!_vm.userPermissions.includes('BudgetRequestDetailsEdit') || !_vm.isMtpPeriod) && !_vm.userPermissions.includes('BudgetRequestDetailsEditAnytime') && !_vm.userPermissions.includes('BudgetRequestApproveEdit') || _vm.isHistoryTabActive,
      "options": _vm.mtpStatus,
      "searchable": false,
      "show-labels": false,
      "value": _vm.selectedMtpStatus,
      "label": "name",
      "track-by": "name"
    },
    on: {
      "select": _vm.beforeChangeMtpStatus
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('span', {
          staticClass: "box",
          class: option.color
        }), _c('strong', [_vm._v(_vm._s(option.name))]), _vm._v(" - " + _vm._s(option.description) + " ")];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var option = _ref2.option;
        return [_c('span', {
          staticClass: "box",
          class: option.color
        }), _c('strong', [_vm._v(_vm._s(option.name))]), _vm._v(" - " + _vm._s(option.description) + " ")];
      }
    }], null, false, 428449110)
  }), _c('div', {
    staticClass: "row pt-3"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('h5', [_vm._v(" Funding status: "), _c('span', {
    staticClass: "d-xl-inline d-block"
  }, [_vm._v(_vm._s(_vm.data.constants.fundingStatus[_vm.mtpData.fundingStatus]) + " ")])])]), _c('div', {
    staticClass: "col-4"
  }, [_c('h5', [_vm._v(" Modified: "), _c('span', {
    staticClass: "d-xl-inline d-block"
  }, [_vm._v(_vm._s(_vm.mtpData.modified))])])]), _c('div', {
    staticClass: "col-4"
  }, [_c('h5', [_vm._v(" Modified by: "), _c('span', {
    staticClass: "d-xl-inline d-block"
  }, [_vm._v(_vm._s(_vm.mtpData.modifiedBy))])])])]), _c('div', {
    staticClass: "row pt-3 pb-3"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm.userPermissions.includes('BudgetRequestDetailsView') ? _c('a', {
    staticClass: "menu-details",
    class: _vm.isHistoryTabActive ? 'pr-3 history' : 'tab-active',
    on: {
      "click": function ($event) {
        return _vm.goToDetails();
      }
    }
  }, [_vm._v("Budget Requests ")]) : _vm._e(), _vm.userPermissions.includes('BudgetRequestDetailsView') ? _c('a', {
    staticClass: "menu-details",
    class: _vm.isHistoryTabActive ? 'tab-active' : 'pl-3 history',
    on: {
      "click": function ($event) {
        return _vm.goToHistory();
      }
    }
  }, [_vm._v("History ")]) : _vm._e()])])], 1), _c('div', {
    staticClass: "col-xl-4 col-lg-5 order-lg-3 order-2 pb-lg-0 pb-4 menu-save"
  }, [!_vm.isHistoryTabActive ? _c('div', [!_vm.isMiniFdMtp ? _c('a', {
    staticClass: "dropdown-toggle-left",
    attrs: {
      "data-toggle": "dropdown",
      "href": ""
    }
  }, [_vm._v("More action ")]) : _vm._e(), _vm.detailsBudgetRequests.isActive && !_vm.isMiniFdMtp ? _c('div', {
    staticClass: "dropdown-menu"
  }, [_vm.mtpData.mtpStatus !== 1 && _vm.mtpData.mtpStatus !== 2 && _vm.mtpData.year === _vm.currentYear && (_vm.userPermissions.includes('BudgetRequestReviewEdit') || _vm.userPermissions.includes('BudgetRequestDetailsEdit')) ? _c('a', {
    staticClass: "dropdown-item pointerLink",
    on: {
      "click": function ($event) {
        return _vm.editControllingInformation();
      }
    }
  }, [_vm._v("Edit supporting information ")]) : _vm._e(), _vm.userPermissions.includes('MtpSiteDocumentsView') ? _c('a', {
    staticClass: "dropdown-item pointerLink",
    on: {
      "click": function ($event) {
        return _vm.exportToExcel();
      }
    }
  }, [_vm._v("Export to Excel ")]) : _vm._e(), (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && _vm.isCurrentMtp === _vm.currentYear && _vm.mtpData.mtpStatus !== 5 ? _c('a', {
    staticClass: "dropdown-item pointerLink",
    on: {
      "click": function ($event) {
        return _vm.deleteBudgetRequest();
      }
    }
  }, [_vm._v(" Remove from MTP ")]) : _vm._e(), _vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime') ? _c('a', {
    staticClass: "dropdown-item pointerLink",
    on: {
      "click": function ($event) {
        return _vm.duplicateBudgetRequest();
      }
    }
  }, [_vm._v("Duplicate Budget Request ")]) : _vm._e(), _vm.isCurrentMtp && _vm.userPermissions.includes('BudgetRequestReviewEdit') ? _c('div', {
    staticClass: "dropdown dropright"
  }, [_c('button', {
    staticClass: "dropdown-toggle dropdown-item pointerLink",
    attrs: {
      "data-toggle": "dropdown"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v(" Update Funding Status ")]), _c('div', {
    staticClass: "dropdown-menu"
  }, [_vm.userPermissions.includes('BudgetRequestReviewEdit') ? _c('div', _vm._l(_vm.data.constants.fundingStatus, function (fundingstatus, fundingStatusKey) {
    return _c('button', {
      key: fundingStatusKey,
      staticClass: "dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.changeFundingStatus(fundingStatusKey);
        }
      }
    }, [_vm._v(" " + _vm._s(fundingstatus) + " "), parseInt(_vm.mtpData.fundingStatus) === parseInt(fundingStatusKey) ? _c('span', [_c('icon', {
      attrs: {
        "name": "check"
      }
    })], 1) : _vm._e()]);
  }), 0) : _vm._e()])]) : _vm._e(), _vm.data.loadingSaveBudgetRequest === true ? _c('loading-spinner') : _vm._e()], 1) : _vm._e(), !_vm.detailsBudgetRequests.isActive && !_vm.isMiniFdMtp ? _c('div', {
    staticClass: "dropdown-menu"
  }, [_vm.userPermissions.includes('MtpSiteDocumentsView') ? _c('button', {
    staticClass: "dropdown-item pointerLink",
    on: {
      "click": function ($event) {
        return _vm.exportToExcel();
      }
    }
  }, [_vm._v(" Export to Excel ")]) : _vm._e(), _vm.createdYear === _vm.currentYear && (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) ? _c('button', {
    staticClass: "dropdown-item pointerLink",
    attrs: {
      "disabled": _vm.mtpData.mtpStatus !== 1 && _vm.mtpData.mtpStatus !== 2
    },
    on: {
      "click": function ($event) {
        return _vm.deleteBrInactive();
      }
    }
  }, [_vm._v(" Delete ")]) : _vm._e(), _vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime') ? _c('button', {
    staticClass: "dropdown-item pointerLink",
    on: {
      "click": function ($event) {
        return _vm.duplicateBudgetRequest();
      }
    }
  }, [_vm._v(" Duplicate Budget Request ")]) : _vm._e(), _vm.data.loadingSaveBudgetRequest === true ? _c('loading-spinner') : _vm._e()], 1) : _vm._e(), (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && _vm.isCurrentMtp ? _c('a', {
    staticClass: "button-edit",
    on: {
      "click": function ($event) {
        return _vm.goToEdit();
      }
    }
  }, [_c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "pencil-alt"
    }
  }), _vm._v(" Edit budget request ")], 1) : _vm._e(), (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && (_vm.mtpYear !== _vm.currentYear || !_vm.detailsBudgetRequests.isActive) ? _c('a', {
    staticClass: "button-edit",
    on: {
      "click": function ($event) {
        return _vm.addToCurrentMtp();
      }
    }
  }, [_vm._v(" Add to current MTP ")]) : _vm._e()]) : _vm._e()])]) : _vm._e(), _vm.isHistoryTabActive ? _c('history', {
    attrs: {
      "budget-request-id": this.detailsBudgetRequests.id
    }
  }) : _c('div', {
    staticClass: "row justify-content-center mb-3"
  }, [_c('div', {
    staticClass: "col-xl-6 col-lg-7"
  }, [_c('div', {
    staticClass: "accordion-budget-details mb-4",
    attrs: {
      "id": "accordion"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_accordion_budget"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "collapseOne",
      "aria-expanded": "true",
      "data-target": "#collapseOne",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" Details "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "collapseOne",
      "aria-labelledby": "heading_accordion_budget",
      "data-parent": "#accordion"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_vm.plant ? _c('div', {
    staticClass: "form-row border-row pb-2"
  }, [_c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Country ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.plant.country.name) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Technology ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.plant.technology) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Plant / River Group ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.plant.plantGroupName) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Plant ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.plant.name) + " ")])]), _vm.detailsBudgetRequests ? _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Unit ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.detailsBudgetRequests.unitName) + " ")])]) : _vm._e(), _vm.mtpData ? _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Risk / Maintenance ID ")]), _vm.mtpData.riskOpportunityId ? _c('div', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, _vm._l(_vm.riskMaintenanceIds, function (id, index) {
    return _c('div', {
      key: index
    }, [_vm.mtpData.attachedExternalId && id.toUpperCase() === _vm.mtpData.attachedExternalId.toUpperCase() ? _c('span', [_c('b', {
      staticClass: "warning-label",
      attrs: {
        "id": "tooltip-target-externalid"
      }
    }, [_vm._v(_vm._s(id) + " ")]), _vm._v(" " + _vm._s(_vm.riskMaintenanceIds.length - 1 === index ? '' : ', ') + " "), _c('b-tooltip', {
      attrs: {
        "custom-class": "hover-table",
        "target": "tooltip-target-externalid",
        "triggers": "hover"
      }
    }, [_vm._v(" Some data fields of this budget request have been copied from this external ID. ")])], 1) : _c('span', {
      key: index
    }, [_vm._v(" " + _vm._s(id) + _vm._s(_vm.riskMaintenanceIds.length - 1 === index ? '' : ', ') + " ")])]);
  }), 0) : _c('p', [_vm._v(" - ")])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Plant Sid ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.plant.plantSId) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Unit Sid ")]), _vm.detailsBudgetRequests ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.detailsBudgetRequests.unitSid) + " ")]) : _vm._e()]), _vm.detailsBudgetRequests ? _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Technology Sid ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.plant.technologySid) + " ")])]) : _vm._e()]) : _vm._e(), _vm.mtpData ? _c('div', {
    staticClass: "form-row border-row pb-2 pt-4"
  }, [_c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.namesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Name (English) ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.detailsBudgetRequests.name) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.nameLocalLanguagesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Name (local language) ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.detailsBudgetRequests.localLanguageName) + " ")])]), _vm.data ? _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.projectTypesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Project Type ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.data.constants.projectTypes[_vm.detailsBudgetRequests.projectType]) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.lumpSumsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Lump sum ")]), _vm.mtpData.lumpSum === true ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" Yes ")]) : _vm._e(), _vm.mtpData.lumpSum === false ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" No ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.startDatesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Start Date ")]), _vm.mtpData.startDate ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.startDate.toString().split('T')[0]) + " ")]) : _c('p', {
    staticClass: "mb-sm-3 mb-0"
  }, [_vm._v(" - ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.endDatesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" End Date ")]), _vm.mtpData.endDate ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.endDate.toString().split('T')[0]) + " ")]) : _c('p', {
    staticClass: "mb-sm-3 mb-0"
  }, [_vm._v(" - ")])])]) : _vm._e(), _vm.mtpData && _vm.data ? _c('div', {
    staticClass: "form-row pb-2 pt-4"
  }, [_c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.aeroClassificationsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" AERO Classification ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.data.constants.aeroClassification[_vm.mtpData.aeroClassification]) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.justificationOnClassificationsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Justification on Classification ")]), _vm.mtpData.justificationOnClassification ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.justificationOnClassification) + " ")]) : _c('p', {
    staticClass: "mb-sm-3 mb-0"
  }, [_vm._v(" - ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.maintenanceTypesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Maintenance Type ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.data.constants.maintenanceType[_vm.mtpData.maintenanceType]) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.outageTypesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Outage Type ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.data.constants.outageType[_vm.mtpData.outageType]) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.outageIdsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Outage ID ")]), _vm.mtpData.outageId && _vm.mtpData.outageId !== '' ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.outageId) + " ")]) : _c('p', {
    staticClass: "mb-sm-3 mb-0"
  }, [_vm._v(" - ")])]), _c('div', {
    staticClass: "form-group col-sm-4 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Procurement Category ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.data.constants.procurementCategory[_vm.mtpData.procurementCategory]) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Comment ")]), _vm.mtpData.comment ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.comment) + " ")]) : _c('p', {
    staticClass: "mb-sm-3 mb-0"
  }, [_vm._v(" - ")])])]) : _vm._e()])])]), _c('div', {
    staticClass: "accordion-review mb-4",
    attrs: {
      "id": "accordionFinance"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_fin"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "accordionFin",
      "aria-expanded": "true",
      "data-target": "#accordionFin",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" FINANCE & PLANNING "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionFin",
      "aria-labelledby": "heading_fin",
      "data-parent": "#accordionFinance"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row mb-4"
  }, [_vm.detailsBudgetRequests ? _c('div', {
    staticClass: "col-sm-6"
  }, [_c('multiselect', {
    attrs: {
      "allow-empty": false,
      "options": Object.keys(_vm.budgetRequestMtpYears),
      "searchable": false,
      "show-labels": false
    },
    on: {
      "input": function ($event) {
        return _vm.reInitMtp(_vm.selectedMtpYear, _vm.detailsBudgetRequests.id);
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (_ref3) {
        var option = _ref3.option;
        return [_vm._v(" " + _vm._s(_vm.budgetRequestMtpYears[option].label) + " ")];
      }
    }, {
      key: "option",
      fn: function (_ref4) {
        var option = _ref4.option;
        return [_vm._v(" " + _vm._s(_vm.budgetRequestMtpYears[option].option) + " ")];
      }
    }], null, false, 348153171),
    model: {
      value: _vm.selectedMtpYear,
      callback: function ($$v) {
        _vm.selectedMtpYear = $$v;
      },
      expression: "selectedMtpYear"
    }
  })], 1) : _vm._e()]), _vm.mtpData && _vm.data ? _c('div', {
    staticClass: "form-row border-row"
  }, [_c('div', {
    staticClass: "form-group col-sm-4"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" WBS (PSP) Element ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.wbsElement) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4"
  }, [_c('h5', {
    staticClass: "mb-0 text-break",
    class: [_vm.validateMappings.planningAndControllingClassificationsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Planning & Controlling Classification ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.data.constants.allPlanningAndControllingClassification[_vm.mtpData.planningAndControllingClassification]) + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Uniper Share of Budget ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.uniperShareOfBudget) + "% ")])]), _c('div', {
    staticClass: "form-group col-sm-4"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Useful Economic Life ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.usefulEconomicLife !== undefined ? ((_vm.mtpData.usefulEconomicLife) + " year(s)") : '-') + " ")])]), _c('div', {
    staticClass: "form-group col-sm-4"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Currency ")]), _vm.data && _vm.mtpData ? [_vm.plant.country.currency.id === _vm.mtpData.selectedCurrencyId ? _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.plant.country.currency.code) + " ")]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "form-group col-sm-4"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Has Planning Costs ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.hasPlanningCosts ? 'Yes' : 'No') + " ")])])]) : _vm._e(), _c('h6', {
    staticClass: "d-block pl-1 pt-3 pb-2"
  }, [_vm._v(" Planning ")]), _c('div', {
    staticClass: "form-row planning"
  }, [_c('div', {
    staticClass: "form-group col-md-12"
  }, [_c('table', {
    staticClass: "pt-2 pb-2 planning-table"
  }, [_vm.mtpData ? _c('thead', [_c('tr', [_c('th', {
    class: [_vm.validateMappings.spentUntilsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Spend until end of " + _vm._s(_vm.mtpData.year - 1) + " ")]), _c('th', {
    class: [_vm.validateMappings.forecastsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Forecast MTP " + _vm._s(_vm.mtpData.year) + " ")]), _c('th', {
    class: [_vm.validateMappings.nextOneYearsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" New MTP " + _vm._s(_vm.mtpData.year + 1) + " ")]), _c('th', {
    class: [_vm.validateMappings.nextTwoYearsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" New MTP " + _vm._s(_vm.mtpData.year + 2) + " ")]), _c('th', {
    class: [_vm.validateMappings.nextThreeYearsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" New MTP " + _vm._s(_vm.mtpData.year + 3) + " ")]), _c('th', {
    class: [_vm.validateMappings.aftersAreEqual ? '' : 'warning-label']
  }, [_vm._v(" After " + _vm._s(_vm.mtpData.year + 3) + " ")]), _c('th', {
    class: [_vm.validateMappings.totalsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Total ")])])]) : _vm._e(), _vm.mtpData ? _c('tbody', [_c('tr', [_c('td', [_c('input', {
    attrs: {
      "readonly": "",
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.mtpData.spendUntilEndOfYear
    }
  })]), _c('td', [_c('input', {
    attrs: {
      "readonly": "",
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.mtpData.forecast
    }
  })]), _c('td', [_c('input', {
    attrs: {
      "readonly": "",
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.mtpData.new1
    }
  })]), _c('td', [_c('input', {
    attrs: {
      "readonly": "",
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.mtpData.new2
    }
  })]), _c('td', [_c('input', {
    attrs: {
      "readonly": "",
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.mtpData.new3
    }
  })]), _c('td', [_c('input', {
    attrs: {
      "readonly": "",
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.mtpData.after
    }
  })]), _c('td', [_c('input', {
    attrs: {
      "readonly": "",
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.mtpData.total
    }
  })])])]) : _vm._e()])])])])])])]), _c('div', {
    staticClass: "col-xl-4 col-lg-5"
  }, [_c('div', {
    staticClass: "accordion-funding-status mb-4",
    attrs: {
      "id": "accordionDataTags"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_tags"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "accordionTags",
      "aria-expanded": "true",
      "data-target": "#accordionTags",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" Focus Topics "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), !_vm.loading && _vm.initialMtpData ? _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionTags",
      "aria-labelledby": "heading_tags",
      "data-parent": "#accordionDataTags"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('tags', {
    attrs: {
      "view-only": _vm.isTagsViewOnly,
      "current-mode": _vm.currentTagsMode,
      "request-id": _vm.initialMtpData.id,
      "initial-tags": (_vm$initialMtpData$mt = _vm.initialMtpData.mtpTags) !== null && _vm$initialMtpData$mt !== void 0 ? _vm$initialMtpData$mt : []
    }
  })], 1)])])]) : _vm._e()]), _c('div', {
    staticClass: "accordion-funding-status mb-4",
    attrs: {
      "id": "accordionFundingStatus"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_funding"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "accordionFunding",
      "aria-expanded": "true",
      "data-target": "#accordionFunding",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" Funding Status "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), !_vm.loading && _vm.detailsBudgetRequests ? _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionFunding",
      "aria-labelledby": "heading_funding",
      "data-parent": "#accordionFundingStatus"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('multiselect', {
    attrs: {
      "allow-empty": false,
      "disabled": !_vm.isCurrentMtp || !_vm.userPermissions.includes('BudgetRequestReviewEdit'),
      "options": _vm.fundingStatuses,
      "searchable": false,
      "show-labels": false,
      "value": _vm.selectedFundingStatus
    },
    on: {
      "input": _vm.changeFundingStatus
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.fundingStatus[props.option]) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.fundingStatus[props.option]) + " ")];
      }
    }], null, false, 823583350)
  })], 1)])])]) : _vm._e()]), _c('div', {
    staticClass: "accordion-funding-status mb-4",
    attrs: {
      "id": "accordionProjectStatus"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_funding"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "accordionProject",
      "aria-expanded": "true",
      "data-target": "#accordionProject",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" Project Status "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), !_vm.loading && _vm.mtpData ? _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionProject",
      "aria-labelledby": "heading_funding",
      "data-parent": "#accordionProjectStatus"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('multiselect', {
    attrs: {
      "allow-empty": false,
      "disabled": _vm.projectStatusDisabled,
      "options": _vm.projectStatuses,
      "searchable": false,
      "show-labels": false,
      "value": _vm.selectedProjectStatus
    },
    on: {
      "input": _vm.changeProjectStatus
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.projectStatusType[props.option]) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.projectStatusType[props.option]) + " ")];
      }
    }], null, false, 495812022)
  })], 1)])])]) : _vm._e()]), _c('div', {
    staticClass: "accordion-funding-status mb-4",
    attrs: {
      "id": "accordionProjectPrioritization"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_funding"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "accordionProjectPrioritizationControl",
      "aria-expanded": "true",
      "data-target": "#accordionProjectPrioritizationControl",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" Project Prioritization "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), !_vm.loading && _vm.mtpData ? _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionProjectPrioritizationControl",
      "aria-labelledby": "heading_funding",
      "data-parent": "#accordionProjectPrioritization"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [!_vm.projectPrioritizationLoading ? _c('pui-button', {
    attrs: {
      "state": "primary",
      "disabled": _vm.disableProjectPrioritization
    },
    on: {
      "click": _vm.runProjectPrioritization
    }
  }, [_vm._v(" Run Project Prioritization ")]) : _c('pui-loader-spinner', {
    attrs: {
      "message": "Running Prioritization..."
    }
  })], 1)])])]) : _vm._e()]), _c('div', {
    staticClass: "accordion-review mb-4",
    attrs: {
      "id": "accordionReview"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_rev"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "accordionRev",
      "aria-expanded": "true",
      "data-target": "#accordionRev",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" Review "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), !_vm.loading && _vm.detailsBudgetRequests && !_vm.reviewSection && _vm.mtpData ? _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionRev",
      "aria-labelledby": "heading_rev",
      "data-parent": "#accordionReview"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-sm-6 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Primary Department ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm.data ? [_vm._v(" " + _vm._s(_vm.data.constants.primaryDepartment[_vm.mtpData.primaryDepartment]) + " ")] : _vm._e()], 2)]), _c('div', {
    staticClass: "form-group col-sm-6 col-6"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Suggested flag ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s((_vm$mtpData$proposedF = _vm.mtpData.proposedFlag) !== null && _vm$mtpData$proposedF !== void 0 ? _vm$mtpData$proposedF : 'N/A') + " ")])]), _c('div', {
    staticClass: "form-group col-sm-12 col-12"
  }, [_c('h5', {
    staticClass: "mb-0 text-break"
  }, [_vm._v(" Suggested flag message ")]), _c('p', {
    staticClass: "mb-sm-3 mb-0 text-break"
  }, [_vm._v(" " + _vm._s((_vm$mtpData$proposedF2 = _vm.mtpData.proposedFlagMessage) !== null && _vm$mtpData$proposedF2 !== void 0 ? _vm$mtpData$proposedF2 : 'N/A') + " ")])])]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" Main Flag ")])]), _c('div', {
    staticClass: "col-12"
  }, [_vm.mtpData.mainFlag === 0 || _vm.mtpData.mainFlag === null ? _c('h6', {
    staticStyle: {
      "color": "#cecece"
    }
  }, [_c('icon', {
    staticClass: "icon-flag",
    attrs: {
      "name": "flag"
    }
  }), _vm._v(" No Flag ")], 1) : _c('h6', [_c('icon', {
    staticClass: "icon-flag",
    attrs: {
      "name": "flag"
    }
  }), _vm._v(" " + _vm._s(_vm.data.constants.flags[_vm.mtpData.mainFlag]) + " ")], 1)])]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" Other Flags ")])]), _c('div', {
    staticClass: "col-12"
  }, [_vm.mtpData.otherFlags.length === 0 ? _c('h6', {
    staticStyle: {
      "color": "#cecece"
    }
  }, [_c('icon', {
    staticClass: "icon-flag",
    attrs: {
      "name": "flag"
    }
  }), _vm._v(" No Flag ")], 1) : _vm._e(), _vm._l(_vm.mtpData.otherFlags, function (otherflags, key) {
    return _c('div', {
      key: key
    }, [_c('h6', [_c('icon', {
      staticClass: "icon-flag",
      attrs: {
        "name": "flag"
      }
    }), _vm._v(" " + _vm._s(_vm.data.constants.flags[otherflags]) + " ")], 1)]);
  })], 2)]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" Comment ")])]), _c('div', {
    staticClass: "col-12"
  }, [!_vm.mtpData.reviewComment ? _c('h6', {
    style: _vm.mtpData.mtpStatus === 1 ? 'color:#cecece;' : ''
  }, [_vm._v(" No comment ")]) : _vm._e(), _vm.mtpData.reviewComment ? _c('h6', [_vm._v(" " + _vm._s(_vm.mtpData.reviewComment.text) + " ")]) : _vm._e(), _vm.mtpData.reviewComment && _vm.formatDateReview ? _c('h6', {
    staticClass: "float-left pr-2 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.formatDateReview) + " ")]) : _vm._e(), _vm.mtpData.reviewers ? _c('h6', {
    staticClass: "nameReview"
  }, [_vm._v(" " + _vm._s(_vm.mtpData.reviewers) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "justify-content-center text-center"
  }, [_vm.userPermissions.includes('BudgetRequestReviewEdit') ? _c('button', {
    staticClass: "btn btn-primary",
    class: _vm.mtpData.mtpStatus === 1 || !_vm.isCurrentMtp ? 'inactive-btn' : '',
    on: {
      "click": function ($event) {
        _vm.mtpData.mtpStatus !== 1 && _vm.isCurrentMtp ? _vm.goToReview() : '';
      }
    }
  }, [_vm._v(" Review ")]) : _vm._e()])])]) : _vm._e(), !_vm.loading && _vm.mtpData && _vm.reviewSection ? _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionRev",
      "aria-labelledby": "heading_rev",
      "data-parent": "#accordionReview"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Primary Department ")])]), _c('div', {
    staticClass: "col-12"
  }, [_vm.data ? _c('h6', [_vm._v(" " + _vm._s(_vm.data.constants.primaryDepartment[_vm.mtpData.primaryDepartment]) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', [_vm._v("Main Flag")]), _c('multiselect', {
    attrs: {
      "allow-empty": true,
      "max": 1,
      "multiple": true,
      "options": _vm.flagsArray,
      "searchable": false,
      "show-labels": false,
      "label": "title",
      "placeholder": "No flag selected",
      "track-by": "title"
    },
    on: {
      "input": function ($event) {
        return _vm.disableFromOtherFlags();
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.title) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.title) + " ")];
      }
    }], null, false, 3473667830),
    model: {
      value: _vm.selectedMainFlag,
      callback: function ($$v) {
        _vm.selectedMainFlag = $$v;
      },
      expression: "selectedMainFlag"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', [_vm._v("Other Flags")]), _c('multiselect', {
    attrs: {
      "close-on-select": false,
      "disabled": _vm.validateMainFlag,
      "multiple": true,
      "options": _vm.otherFlagsArray,
      "searchable": false,
      "show-labels": false,
      "label": "title",
      "placeholder": "No flag(s) selected",
      "track-by": "title"
    },
    on: {
      "input": function ($event) {
        return _vm.disableFromFlags();
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.title) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.title) + " ")];
      }
    }], null, false, 3473667830),
    model: {
      value: _vm.selectedOtherFlags,
      callback: function ($$v) {
        _vm.selectedOtherFlags = $$v;
      },
      expression: "selectedOtherFlags"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', [_vm._v("Comment")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reviewBudgetRequestComment,
      expression: "reviewBudgetRequestComment"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "Please enter a comment"
    },
    domProps: {
      "value": _vm.reviewBudgetRequestComment
    },
    on: {
      "keyup": function ($event) {
        return _vm.checkempty();
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.reviewBudgetRequestComment = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('h5', [_c('i', [_vm._v("A comment must be provided in order to submit the review.")])])])]), _c('div', {
    staticClass: "justify-content-center text-right pt-2"
  }, [_c('button', {
    staticClass: "btn btn-link",
    on: {
      "click": function ($event) {
        return _vm.cancelReview();
      }
    }
  }, [_vm._v(" CANCEL ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.validateReviewComment
    },
    on: {
      "click": function ($event) {
        !_vm.validateReviewComment ? _vm.submitReview() : '';
      }
    }
  }, [_vm._v(" SUBMIT REVIEW ")])])])]) : _vm._e()]), _vm.externalList.length > 0 ? _c('slick', {
    ref: "slick",
    staticClass: "external-slider mt-3",
    attrs: {
      "options": _vm.slickOptions
    }
  }, _vm._l(_vm.externalList, function (elem, key) {
    return _c('div', {
      key: key
    }, [_vm.externalList[key] !== null && elem.type === 'M' ? _c('maintenance-budget-request', {
      attrs: {
        "currency": _vm.plant.country.currency.code,
        "disable-copy": true,
        "element-index": key,
        "hide-copy": true,
        "is-details": true,
        "maintenance": elem
      }
    }) : _vm._e(), _vm.externalList[key] !== null && elem.type === 'R' ? _c('risk-budget-request', {
      attrs: {
        "element-index": key,
        "hide-copy": true,
        "risk": elem
      }
    }) : _vm._e(), _vm.externalList[key] !== null && elem.type === 'O' ? _c('opportunity-budget-request', {
      attrs: {
        "element-index": key,
        "hide-copy": true,
        "opportunity": elem
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()], 1)])], 1) : _vm._e()], 1) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }