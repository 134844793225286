import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import '~/components/home/home.scss';
import 'vue-awesome/icons/chevron-down';
import 'vue-awesome/icons/chevron-right';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/save';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/pencil-alt';
import 'vue-awesome/icons/flag';
import { format } from 'date-fns';
import { EventBus } from '~/utils/eventbus';
import { Data, Helper, User } from '~/utils';
import { MtpPeriod, ReviewBudgetRequest, ValidateMappings } from '~/utils/interfaces';
import { MtpService } from '~/services/mtp-service';
import { FileService } from '~/services/file-service';
import HistoryComponent from './history/history.vue';
import moment from 'moment-timezone';
import MaintenanceBudgetRequestComponent from '.././budget-request/maintenance-budget-request/maintenance-budget-request.vue';
import RiskBudgetRequestComponent from '.././budget-request/risk-budget-request/risk-budget-request.vue';
import OpportunityBudgetRequestComponent from '.././budget-request/opportunity-budget-request/opportunity-budget-request.vue';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import EditControllingModalComponent from '../data-input/edit-controlling-modal/edit-controlling-modal.vue';
import { ExportPayload } from '~/models/services/reporting';
import Tags from '~/components/focus-topics/tags/tags.vue';
import { TagsMode } from '~/models/services/tags';
import { EditProjectStatusPayload } from '~/models/services/budget-request';
import { MasterService } from '~/services/master-service'
import { isExtendedCurrentMtp } from '~/utils/mtp-utils';

@Component({
    components: {
        'history': HistoryComponent,
        'maintenance-budget-request': MaintenanceBudgetRequestComponent,
        'risk-budget-request': RiskBudgetRequestComponent,
        'opportunity-budget-request': OpportunityBudgetRequestComponent,
        'edit-controlling-modal': EditControllingModalComponent,
        Slick,
        Tags,
    }
})
export default class DetailsPageComponent extends Vue {
    private BudgetRequestsService = MasterService.instance.budgetRequestsService;
    private CrossAppLinkingService = MasterService.instance.crossAppLinkingService;
    private AdminService = MasterService.instance.adminService;
    private projectPrioritizationLoading = false;
    private loading: boolean = false;
    private validateReviewComment: boolean = true;
    private validateMainFlag: boolean = true;
    private detailsBudgetRequests = null;
    private plant = null;
    private mtpData = null;
    private initialMtpData = null;
    private changeMtpStatus = {
        globalIds: [],
        mtpYear: 0,
        mtpStatus: 1
    };
    private ExportBudgetRequestService: FileService = new FileService();
    private PlantsService = MasterService.instance.plantsService;
    private MtpService: MtpService = new MtpService();
    private userPermissions = <any>[];
    private data: Data = Data.Instance;
    private reviewSection: boolean = false;
    private reviewBudgetRequest: ReviewBudgetRequest = <ReviewBudgetRequest>{
        budgetRequestId: '',
        mtpYear: null,
        mainFlag: null,
        otherFlags: [],
        comment: {
            date: new Date(Date.now()),
            text: ''
        },
        mtpStatus: null
    };
    private selectedOtherFlags = [];
    private selectedMainFlag: any = {};
    private reviewBudgetRequestComment = null;
    private selectedUpdate: boolean = false;
    private selectedMtpStatus = null;
    private selectedFundingStatus = null;
    private selectedProjectStatus = null;
    private selectedMtpYear = null;
    private mtpStatus = [];
    private fundingStatuses = [];
    private projectStatuses = [];
    private formatDateReview = null;
    private exportBudgetRequests = {
        year: null,
        miniFdYear: null,
        globalIds: []
    } as ExportPayload;
    private deleteBudgetRequests = {
        globalIds: [],
        isActive: null,
        mtpYear: null
    };
    private setToInactiveBr = {
        globalIds: [],
        mtpYear: null
    };
    private flagsArray: any[] = [];
    private allFlags: any[] = [];
    private otherFlagsArray: any[] = [];
    private idOfSelectedMtpStatus = null;
    private isStateEditModalActive: boolean = false;
    private isFlaggedModalActive: boolean = false;
    private isReviewedModalActive: boolean = false;
    private isAccessDeniedModalActive: boolean = false;
    private a: boolean = false;
    private currentYear = new Date(Date.now()).getFullYear();
    private createdYear = null;
    private budgetRequestId = null;
    private isHistoryTabActive: boolean = false;
    private previousSelectedMtp = null;
    private externalIdsDetails = {
        budgetRequestId: '',
        externalIds: [],
        userSelectionMaintenancePlanningIds: [],
        mtpYear: 0
    };
    private externalList = [];
    private slickOptions = {
        slidesToShow: 1,
        infinite: false,
        accessibility: true,
        adaptiveHeight: false,
        arrows: true,
        dots: false,
        draggable: false,
        edgeFriction: 0.30,
        swipe: false
    };
    private isMtpPeriod: boolean = false;
    private mtpPeriod: MtpPeriod = <MtpPeriod>{
        year: 0,
        mtpStartDate: null,
        mtpEndDate: null
    };
    private mappingWarning: string = '';
    private validateMappings: ValidateMappings = <ValidateMappings>{
        namesAreEqual: true,
        nameLocalLanguagesAreEqual: true,
        projectTypesAreEqual: true,
        maintenanceTypesAreEqual: true,
        aeroClassificationsAreEqual: true,
        justificationOnClassificationsAreEqual: true,
        lumpSumsAreEqual: true,
        planningAndControllingClassificationsAreEqual: true,
        outageIdsAreEqual: true,
        outageTypesAreEqual: true,
        forecastsAreEqual: true,
        spentUntilsAreEqual: true,
        nextOneYearsAreEqual: true,
        nextTwoYearsAreEqual: true,
        nextThreeYearsAreEqual: true,
        aftersAreEqual: true,
        totalsAreEqual: true,
        startDatesAreEqual: true,
        endDatesAreEqual: true
    };
    private showWarning = false;
    private externalIdType = '';
    private riskMaintenanceIds = [];
    private noValidationWarning = false;
    private mtpYear = null;
    private miniFdYear = null;
    private lastActiveMtpPeriodYear: number = 0;

    private currentTagsMode: TagsMode = TagsMode.BR;

    constructor() {
        super();
    }

    private get isMiniFdMtp(): boolean {
        return this.miniFdYear !== 0;
    }

    private get isCurrentMtp(): boolean {
        return this.detailsBudgetRequests?.isActive && (this.mtpYear === this.currentYear || isExtendedCurrentMtp(this.mtpYear, this.userPermissions)) && !this.isMiniFdMtp;
    }

    private get isTagsViewOnly(): boolean {
        return !((this.userPermissions.includes('BudgetRequestDetailsEdit') && ((this.isCurrentMtp && this.isMtpPeriod) || (this.isLastActivePeriod && this.lastActiveMtpPeriodYear === this.currentYear))) || (this.userPermissions.includes('FocusTopicEdit') && this.isLastActivePeriod && !this.isMtpPeriod));
    }

    private get isLastActivePeriod(): boolean {
        return this.lastActiveMtpPeriodYear === this.mtpYear;
    }

    private get budgetRequestMtpYears() {
        const mtpList = this.detailsBudgetRequests?.mtpList;
        const miniFdList = this.detailsBudgetRequests?.miniFdList;
        const mtpYears = {};

        mtpList?.forEach(mtp => {
            mtpYears[`${mtp}-0`] = {
                label: 'MTP ' + Helper.getYearsForLabel(mtp),
                option: `MTP ${mtp}`
            };
        });

        miniFdList?.forEach(miniFdMtp => {
            mtpYears[`${miniFdMtp.mtpYear}-${miniFdMtp.miniFdYear}`] = {
                label: 'Change Request ' + Helper.getYearsForLabel(miniFdMtp.mtpYear),
                option: `Change Request ${miniFdMtp.mtpYear}`
            };
        });

        return mtpYears;
    }

    async created() {
        this.mtpYear = parseInt(this.$route.query.year as string);
        this.miniFdYear = parseInt(this.$route.query.miniFdYear as string) || 0;

        this.userPermissions = await User.getPerm();
        if (!this.userPermissions.includes('BudgetRequestDetailsView')) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }

        // Check current MTP period to enable/disable edit BR
        if (this.userPermissions.includes('BudgetRequestDetailsEdit')) {
            if (localStorage.mtpPeriod) {
                this.mtpPeriod = JSON.parse(localStorage.mtpPeriod);
            } else {
                try {
                    this.mtpPeriod = await this.MtpService.GetMtpPeriod(this.currentYear);
                    localStorage.setItem('mtpPeriod', JSON.stringify(this.mtpPeriod));
                } catch (error) {
                    if (error.response !== undefined && error.response.status === 400) {
                        localStorage.removeItem('mtpPeriod');
                    }
                }
            }

            const mtpPeriodsList = await this.MtpService.getMtpPeriods();
            if (mtpPeriodsList.length > 0) {
                const currentDate = new Date();
                this.lastActiveMtpPeriodYear = mtpPeriodsList
                    .sort((a, b) => new Date(b.mtpEndDate).getTime() - new Date(a.mtpEndDate).getTime())
                    .find((obj: MtpPeriod) => new Date(obj.mtpEndDate) < currentDate)?.year;
            }

            if (this.mtpPeriod && this.mtpPeriod.mtpEndDate && this.mtpPeriod.mtpStartDate) {
                let currentDate = format(new Date(Date.now()), 'yyyy-MM-dd\'T00:00:00\'');
                this.mtpPeriod.mtpEndDate = format(new Date(this.mtpPeriod.mtpEndDate), 'yyyy-MM-dd\'T00:00:00\'');

                if (this.mtpPeriod.mtpStartDate <= currentDate && this.mtpPeriod.mtpEndDate >= currentDate) {
                    this.isMtpPeriod = true;
                }
            }
        } else {
            this.isMtpPeriod = false;
        }
    }

    async mounted() {
        EventBus.$on(EventBus.BUDGET_REQUEST.YES_CHANGES_MODAL, async () => {
            this.isStateEditModalActive = false;
            this.data.changedMtpLoading = true;
            this.changeMtpStatus.mtpStatus = 1;
            $('#stateEditModal').modal('hide');
            await this.changeMtp();
            this.data.changedMtpLoading = false;
            this.a = true;
            this.$router.push({
                path: 'budget-request',
                query: { year: this.$route.query.year, globalId: this.$route.query.globalId }
            });
        });
        EventBus.$on(EventBus.UPDATE_MTP_STATUS.UPDATE_MTP_MODAL, async () => {
            this.changeToDraft(this.idOfSelectedMtpStatus);
            $('#updateMtpModal').modal('hide');
            this.isStateEditModalActive = false;
        });
        EventBus.$on(EventBus.MTP_STATUS.UPDATE_FLAGGED_MODAL, async () => {
            $('#mtpStatusFlaggedModal').modal('hide');
            $('#mtpStatusReviewedModal').modal('hide');
            await this.loadReview();
        });
        EventBus.$once(EventBus.REMOVE_BUDGET_REQUEST.REMOVE_MODAL, (stateBr) => {
            if (stateBr === 'Delete') {
                $('#removeBrModal').modal('hide');
                this.isStateEditModalActive = false;
                this.removeBr();
            } else if (stateBr === 'SetInactive') {
                $('#removeBrModal').modal('hide');
                this.isStateEditModalActive = false;
                this.setBudgetRequestsInactive();
            }
        });
        EventBus.$once(EventBus.REMOVE_BUDGET_REQUEST.SET_INACTIVE_MODAL, () => {
            $('#setInactiveModal').modal('hide');
            this.setBudgetRequestsInactive();
            this.isStateEditModalActive = false;
        });

        EventBus.$once(EventBus.DELETE_BUDGET_REQUEST.DELETE_MODAL, () => {
            $('#confirmDeleteModal').modal('hide');
            this.removeBr();
            this.isStateEditModalActive = false;
        });

        if (this.data.constants) {
            await this.load();
        }
    }

    beforeDestroy() {
        EventBus.$off();
    }

    @Watch('data.constants')
    async onSelectedPlantChanged(val: string, oldVal: string) {
        if (val != null && this.$route.path === '/details-page') {
            await this.load();
        }
    }

    beforeRouteLeave(to, from, next) {
        sessionStorage.removeItem('redirectUrl');
        if (to.name !== 'budgetRequest' && localStorage.selectedMtp !== '0' && this.mtpData) {
            localStorage.setItem('selectedMtp', this.mtpData.year);
        }
        if (this.detailsBudgetRequests && !this.detailsBudgetRequests.isActive) {
            localStorage.setItem('selectedMtp', '0');
        }
        if (this.reviewSection && !this.isFlaggedModalActive && !this.isReviewedModalActive) {
            $('#confirmationReviewModal').modal('show');
            next(false);
            if (this.a) {
                next();
            }
            EventBus.$on(EventBus.REVIEW_CHANGES.DISCARD_CHANGES_MODAL, () => {
                $('#confirmationReviewModal').modal('hide');
                next();
                if (this.isStateEditModalActive) {
                    $('#stateEditModal').modal('show');
                }
            });
            EventBus.$on(EventBus.REVIEW_CHANGES.SAVE_CHANGES_MODAL, async () => {
                $('#confirmationReviewModal').modal('hide');
                next();
            });
        }

        if (this.isStateEditModalActive) {
            next(false);
        }
        if (this.isFlaggedModalActive) {
            next(false);
        }
        if (this.isReviewedModalActive) {
            next(false);
        }
        if (!this.reviewSection && !this.isStateEditModalActive && !this.isFlaggedModalActive && !this.isReviewedModalActive) {
            next(true);
        }
    }

    @Watch('selectedMtpStatus')
    async onSelectedMtpStatusChanged(val: string, oldVal: string) {
        if (val != null) {
            if (this.selectedMtpStatus.name === 'Draft') {
                if ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) {
                    this.mtpStatus[1].$isDisabled = false;
                } else {
                    this.mtpStatus[1].$isDisabled = true;
                }
                this.mtpStatus[2].$isDisabled = true;
                this.mtpStatus[3].$isDisabled = true;
                this.mtpStatus[4].$isDisabled = true;
            }
            if (this.selectedMtpStatus.name === 'Submitted') {
                if ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) {
                    this.mtpStatus[0].$isDisabled = false;
                } else {
                    this.mtpStatus[0].$isDisabled = true;
                }
                this.mtpStatus[1].$isDisabled = false;
                this.mtpStatus[2].$isDisabled = true;
                this.mtpStatus[3].$isDisabled = true;
                this.mtpStatus[4].$isDisabled = true;
            }
            if (this.selectedMtpStatus.name === 'Flagged') {
                if ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) {
                    this.mtpStatus[0].$isDisabled = false;
                } else {
                    this.mtpStatus[0].$isDisabled = true;
                }
                this.mtpStatus[1].$isDisabled = true;
                this.mtpStatus[2].$isDisabled = false;
                this.mtpStatus[3].$isDisabled = true;
                this.mtpStatus[4].$isDisabled = true;
            }
            if (this.selectedMtpStatus.name === 'Reviewed') {
                if ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) {
                    this.mtpStatus[0].$isDisabled = false;
                } else {
                    this.mtpStatus[0].$isDisabled = true;
                }
                this.mtpStatus[1].$isDisabled = true;
                this.mtpStatus[2].$isDisabled = true;
                this.mtpStatus[3].$isDisabled = false;
                if (this.mtpData.new1 > 0 && this.userPermissions.includes('BudgetRequestApproveEdit')) {
                    this.mtpStatus[4].$isDisabled = false;
                } else {
                    this.mtpStatus[4].$isDisabled = true;
                }
            }
            if (this.selectedMtpStatus.name === 'Approved') {
                if ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) {
                    this.mtpStatus[0].$isDisabled = false;
                } else {
                    this.mtpStatus[0].$isDisabled = true;
                }
                this.mtpStatus[1].$isDisabled = true;
                this.mtpStatus[2].$isDisabled = true;
                this.mtpStatus[3].$isDisabled = true;
                this.mtpStatus[4].$isDisabled = false;
            }
        }
    }

    private async load() {
        this.loading = true;

        try {
            if (this.$route.query.globalId) {
                this.detailsBudgetRequests = await this.BudgetRequestsService.getBudgetRequestDetails(this.mtpYear, (this.$route.query.globalId as string), this.miniFdYear);
            } else if (this.$route.query.id) {
                this.detailsBudgetRequests = await this.BudgetRequestsService.GetBudgetRequestDetailsByInternalId((this.$route.query.year as string), (this.$route.query.id as string));
            } else {
                this.$router.push('/budget-requests');
            }

            this.plant = await this.PlantsService.getPlants(this.detailsBudgetRequests.plantSid);
            this.plant.units.forEach(e => {
                if (e.unitSid === this.detailsBudgetRequests.unitSid) {
                    this.detailsBudgetRequests.unitName = e.name;
                }
            });

            this.mtpData = await this.MtpService.getMtpData(this.detailsBudgetRequests.id, this.mtpYear, this.miniFdYear);
            this.initialMtpData = {
                ...this.mtpData,
            };

            localStorage.setItem('selectedMtp', this.mtpData.year);

            // Get external data to populate external panels
            if (this.mtpData.riskOpportunityId && this.detailsBudgetRequests.planning.riskOpportunityId && this.detailsBudgetRequests.planning.riskOpportunityId.trim().length > 0) {
                this.getExternalDataForDetails();
            }
        } catch (error) {
            if (error) {
                this.$router.push('activity-tracking');
                this.$root.$emit('showErrorToastGlobalIdNotFound', error.response.data);
                this.loading = false;
                return;
            }
        }
        if (this.mtpData.reviewComment) {
            this.formatDateReview = moment(Helper.UtcToLocalTime(this.mtpData.reviewComment.date)).format('DD/MM/YYYY');
        }
        if (this.mtpData.startDate) {
            this.mtpData.startDate = moment(Helper.UtcToLocalTime(this.mtpData.startDate)).format('DD/MM/YYYY');
        }
        if (this.mtpData.endDate) {
            this.mtpData.endDate = moment(Helper.UtcToLocalTime(this.mtpData.endDate)).format('DD/MM/YYYY');
        }
        this.mtpData.modified = moment(Helper.UtcToLocalTime(this.mtpData.modified)).format('DD/MM/YYYY - HH:mm');
        this.selectedMtpYear = `${this.mtpYear}-${this.miniFdYear}`;
        if (this.data.constants) {
            this.mtpStatus = Object.values(this.data.constants.mtpStatus);
            this.addIdToMtpStatusObj();
            this.selectedMtpStatus = this.data.constants.mtpStatus[this.mtpData.mtpStatus];

            this.selectedProjectStatus = this.mtpData.projectStatus;
            this.selectedFundingStatus = this.mtpData.fundingStatus;
            this.fundingStatuses = Object.keys(this.data.constants.fundingStatus);
            this.projectStatuses = Object.keys(this.data.constants.projectStatusType);
        }
        this.changeMtpStatus.globalIds.push(this.detailsBudgetRequests.globalId);
        this.changeMtpStatus.mtpYear = this.detailsBudgetRequests.planning.year;
        this.createdYear = new Date(this.detailsBudgetRequests.created).getFullYear();
        this.loading = false;
    }

    private async getExternalDataForDetails() {
        // Set external ids for api call
        this.externalIdsDetails.budgetRequestId = this.detailsBudgetRequests.id;
        this.mtpData.riskOpportunityId = this.mtpData.riskOpportunityId.replace(/\s/g, '');
        this.externalIdsDetails.externalIds = this.mtpData.riskOpportunityId.split(',');
        if (this.mtpData.maintenancePlanningIds && this.mtpData.maintenancePlanningIds.trim().length > 0) {
            this.mtpData.maintenancePlanningIds = this.mtpData.maintenancePlanningIds.replace(/\s/g, '');
            this.externalIdsDetails.userSelectionMaintenancePlanningIds = this.mtpData.maintenancePlanningIds.split(',').map(e => parseInt(e));
        }
        this.externalIdsDetails.mtpYear = this.mtpData.year;

        let externalData = null;

        try {
            externalData = await this.CrossAppLinkingService.getExternalIds(this.externalIdsDetails);
        } catch (error) {
            externalData = error.response.data;
        }

        // If an external id is deleted, we're adding a 'Deleted' label for that id
        let errorMsg = externalData.errorMessage;
        this.riskMaintenanceIds = this.mtpData.riskOpportunityId.split(',');
        this.riskMaintenanceIds.forEach((id, index) => {
            if (errorMsg && errorMsg.indexOf(id) !== -1) {
                this.riskMaintenanceIds[index] = id + ' - Deleted';
            }
        });

        if (externalData.data && externalData.data.length === undefined) {
            this.externalIdType = this.setExternalIdType(this.externalIdsDetails.externalIds, 0);

            // Format dates
            if (externalData && this.externalIdType === 'M') {
                this.formatDates(externalData.data);
            }

            this.externalList.splice(0, 1, {
                id: this.externalIdsDetails.externalIds[0],
                type: this.externalIdType,
                data: externalData.data
            });


        } else if (externalData.data && externalData.data.length >= 1) {
            // Sort data by external ids
            this.sortExternalData(externalData.data, this.externalIdsDetails.externalIds);

            externalData.data.forEach((e, ind) => {
                this.externalIdType = this.setExternalIdType(this.externalIdsDetails.externalIds, ind);

                // Format dates
                if (this.externalIdType === 'M') {
                    this.formatDates(e);
                }

                this.externalList.splice(ind, 1, {
                    id: this.externalIdsDetails.externalIds[ind],
                    type: this.externalIdType,
                    data: e
                });
            });
        }

        // Set warning messages
        if (externalData.validationData !== null) {
            this.checkMappings(externalData.validationData);
        } else {
            this.noValidationWarning = true;
        }
    }

    private checkMappings(validationData: any) {
        this.mappingWarning = '';

        if (validationData) {
            if (validationData.namesAreEqual !== undefined) {
                if (!validationData.namesAreEqual) {
                    this.validateMappings.namesAreEqual = validationData.namesAreEqual;
                    this.mappingWarning = this.mappingWarning + ' Name';
                    this.showWarning = true;
                }
            }
            if (validationData.nameLocalLanguagesAreEqual !== undefined) {
                if (!validationData.nameLocalLanguagesAreEqual) {
                    this.validateMappings.nameLocalLanguagesAreEqual = validationData.nameLocalLanguagesAreEqual;
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Name (local language)' : this.mappingWarning + ', Name (local language)';
                    this.showWarning = true;
                }
            }
            if (validationData.localLanguageNamesAreEqual !== undefined) {
                if (!validationData.localLanguageNamesAreEqual) {
                    this.validateMappings.nameLocalLanguagesAreEqual = validationData.localLanguageNamesAreEqual;
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Name (local language)' : this.mappingWarning + ', Name (local language)';
                    this.showWarning = true;
                }
            }
            if (validationData.projectTypesAreEqual !== undefined) {
                this.validateMappings.projectTypesAreEqual = validationData.projectTypesAreEqual;
                if (!validationData.projectTypesAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Project Type' : this.mappingWarning + ', Project Type';
                    this.showWarning = true;
                }
            }
            if (validationData.maintenanceTypesAreEqual !== undefined) {
                this.validateMappings.maintenanceTypesAreEqual = validationData.maintenanceTypesAreEqual;
                if (!validationData.maintenanceTypesAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Maintenance Type' : this.mappingWarning + ', Maintenance Type';
                    this.showWarning = true;
                }
            }
            if (validationData.aeroClassificationsAreEqual !== undefined) {
                this.validateMappings.aeroClassificationsAreEqual = validationData.aeroClassificationsAreEqual;
                if (!validationData.aeroClassificationsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'AERO Classification' : this.mappingWarning + ', AERO Classification';
                    this.showWarning = true;
                }
            }
            if (validationData.aeroClassificationAreEqual !== undefined) {
                this.validateMappings.aeroClassificationsAreEqual = validationData.aeroClassificationAreEqual;
                if (!validationData.aeroClassificationAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'AERO Classification' : this.mappingWarning + ', AERO Classification';
                    this.showWarning = true;
                }
            }
            if (validationData.justificationOnClassificationsAreEqual !== undefined) {
                this.validateMappings.justificationOnClassificationsAreEqual = validationData.justificationOnClassificationsAreEqual;
                if (!validationData.justificationOnClassificationsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Justification on Classification' : this.mappingWarning + ', Justification on Classification';
                    this.showWarning = true;
                }
            }
            if (validationData.lumpSumsAreEqual !== undefined) {
                this.validateMappings.lumpSumsAreEqual = validationData.lumpSumsAreEqual;
                if (!validationData.lumpSumsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Lump Sum' : this.mappingWarning + ', Lump Sum';
                    this.showWarning = true;
                }
            }
            if (validationData.planningAndControllingClassificationsAreEqual !== undefined) {
                this.validateMappings.planningAndControllingClassificationsAreEqual = validationData.planningAndControllingClassificationsAreEqual;
                if (!validationData.planningAndControllingClassificationsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Planning & Controlling Classification' : this.mappingWarning + ', Planning & Controlling Classification';
                    this.showWarning = true;
                }
            }
            if (validationData.outageIdsAreEqual !== undefined) {
                this.validateMappings.outageIdsAreEqual = validationData.outageIdsAreEqual;
                if (!validationData.outageIdsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Outage ID' : this.mappingWarning + ', Outage ID';
                    this.showWarning = true;
                }
            }
            if (validationData.outageTypesAreEqual !== undefined) {
                this.validateMappings.outageTypesAreEqual = validationData.outageTypesAreEqual;
                if (!validationData.outageTypesAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Outage Type' : this.mappingWarning + ', Outage Type';
                    this.showWarning = true;
                }
            }
            // For Risk/Opportunity id check data forecastsAreEqual but validation and highlighting is done for the Total value of the BR
            if (this.externalIdType === 'R' || this.externalIdType === 'O') {
                if (validationData.pmlTotalsAndRiskStrategyCostAreEqual !== undefined) {
                    this.validateMappings.totalsAreEqual = validationData.pmlTotalsAndRiskStrategyCostAreEqual;
                    if (!validationData.pmlTotalsAndRiskStrategyCostAreEqual) {
                        this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Total ' : this.mappingWarning + ', Total ';
                        this.showWarning = true;
                    }
                }
            }
            if (this.externalIdType === 'M') {
                if (validationData.forecastsAreEqual !== undefined) {
                    this.validateMappings.forecastsAreEqual = validationData.forecastsAreEqual;
                    if (!validationData.forecastsAreEqual) {
                        this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Forecast MTP ' + this.detailsBudgetRequests.planning.year : this.mappingWarning + ', Forecast MTP ' + this.detailsBudgetRequests.planning.year;
                        this.showWarning = true;
                    }
                }
            }
            if (validationData.spentUntilsAreEqual !== undefined) {
                this.validateMappings.spentUntilsAreEqual = validationData.spentUntilsAreEqual;
                if (!validationData.spentUntilsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Spent until end of ' + (this.detailsBudgetRequests.planning.year - 1) : this.mappingWarning + ', Spent until end of ' + (this.detailsBudgetRequests.planning.year - 1);
                    this.showWarning = true;
                }
            }
            if (validationData.nextOneYearsAreEqual !== undefined) {
                this.validateMappings.nextOneYearsAreEqual = validationData.nextOneYearsAreEqual;
                if (!validationData.nextOneYearsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'New MTP ' + (this.detailsBudgetRequests.planning.year + 1) : this.mappingWarning + ', New MTP ' + (this.detailsBudgetRequests.planning.year + 1);
                    this.showWarning = true;
                }
            }
            if (validationData.nextTwoYearsAreEqual !== undefined) {
                this.validateMappings.nextTwoYearsAreEqual = validationData.nextTwoYearsAreEqual;
                if (!validationData.nextTwoYearsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'New MTP ' + (this.detailsBudgetRequests.planning.year + 2) : this.mappingWarning + ', New MTP ' + (this.detailsBudgetRequests.planning.year + 2);
                    this.showWarning = true;
                }
            }
            if (validationData.nextThreeYearsAreEqual !== undefined) {
                this.validateMappings.nextThreeYearsAreEqual = validationData.nextThreeYearsAreEqual;
                if (!validationData.nextThreeYearsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'New MTP ' + (this.detailsBudgetRequests.planning.year + 3) : this.mappingWarning + ', New MTP ' + (this.detailsBudgetRequests.planning.year + 3);
                    this.showWarning = true;
                }
            }
            if (validationData.aftersAreEqual !== undefined) {
                this.validateMappings.aftersAreEqual = validationData.aftersAreEqual;
                if (!validationData.aftersAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'After ' + (this.detailsBudgetRequests.planning.year + 3) : this.mappingWarning + ', After ' + (this.detailsBudgetRequests.planning.year + 3);
                    this.showWarning = true;
                }
            }
            if (validationData.startDatesAreEqual !== undefined) {
                if (!validationData.startDatesAreEqual && this.externalList[0].data.dataToBeMappedInPml.startDate) {
                    this.validateMappings.startDatesAreEqual = validationData.startDatesAreEqual;
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Start Date' : this.mappingWarning + ', Start Date';
                    this.showWarning = true;
                }
            }
            if (validationData.endDatesAreEqual !== undefined) {
                if (!validationData.endDatesAreEqual && this.externalList[0].data.dataToBeMappedInPml.endDate) {
                    this.validateMappings.endDatesAreEqual = validationData.endDatesAreEqual;
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'End Date' : this.mappingWarning + ', End Date';
                    this.showWarning = true;
                }
            }
        }
    }

    private clearExternalData() {
        this.externalIdsDetails = {
            budgetRequestId: '',
            externalIds: [],
            userSelectionMaintenancePlanningIds: [],
            mtpYear: 0
        };
        this.externalList = [];
        this.externalIdType = '';
        this.riskMaintenanceIds = [];
        this.noValidationWarning = false;
        this.clearMappingsWarning();
    }

    private clearMappingsWarning() {
        this.validateMappings = {
            namesAreEqual: true,
            nameLocalLanguagesAreEqual: true,
            projectTypesAreEqual: true,
            maintenanceTypesAreEqual: true,
            aeroClassificationsAreEqual: true,
            justificationOnClassificationsAreEqual: true,
            lumpSumsAreEqual: true,
            planningAndControllingClassificationsAreEqual: true,
            outageIdsAreEqual: true,
            outageTypesAreEqual: true,
            forecastsAreEqual: true,
            spentUntilsAreEqual: true,
            nextOneYearsAreEqual: true,
            nextTwoYearsAreEqual: true,
            nextThreeYearsAreEqual: true,
            aftersAreEqual: true,
            totalsAreEqual: true,
            startDatesAreEqual: true,
            endDatesAreEqual: true
        };
        this.mappingWarning = '';
        this.showWarning = false;
    }

    private setExternalIdType(externalIds: any, index: number) {
        let currentIdType = externalIds[index].slice(externalIds[index].length - 1).toUpperCase();

        if (currentIdType === 'M' || currentIdType === 'S') {
            return 'M'; // for Maintenance and Statutory
        } else if (currentIdType === 'R') {
            return 'R'; // for Risk
        } else if (currentIdType === 'O') {
            return 'O'; // for Opportunity
        } else {
            return '';
        }
    }

    private goToHistory() {
        this.isHistoryTabActive = true;
    }

    private goToDetails() {
        this.isHistoryTabActive = false;
    }

    private addIdToMtpStatusObj() {
        let i = 1;
        this.mtpStatus.forEach((e) => {
            e.id = i;
            i++;
        });
    }

    private goToEdit() {
        if (!this.reviewSection) {
            if (this.mtpData.mtpStatus === 1) {
                const year = this.mtpYear !== this.currentYear && isExtendedCurrentMtp(this.mtpYear, this.userPermissions) ? this.currentYear - 1 : this.currentYear

                this.$router.push({
                    path: 'budget-request',
                    query: { year: year.toString(), globalId: this.detailsBudgetRequests.globalId }
                });
            } else {
                if (this.changeMtpStatus != null) {
                    $('#stateEditModal').modal('show');
                    this.isStateEditModalActive = true;
                }
            }
        } else {
            $('#confirmationReviewModal').modal('show');
            this.isStateEditModalActive = true;
            EventBus.$on(EventBus.REVIEW_CHANGES.DISCARD_CHANGES_MODAL, () => {
                $('#confirmationReviewModal').modal('hide');
                this.reviewSection = false;
                setTimeout(() => {
                    if (this.isStateEditModalActive) {
                        $('#stateEditModal').modal('show');
                    }
                }, 350);

            });
        }
    }

    private async reInitMtp(selectedMtpYear, id) {
        this.loading = true;
        this.mtpYear = parseInt(selectedMtpYear.toString().split('-')[0]);
        this.miniFdYear = selectedMtpYear.toString().split('-').length > 1 ? parseInt(selectedMtpYear.toString().split('-')[1]) : 0;
        localStorage.setItem('selectedMtp', this.mtpYear);
        // reset flag after click view details from move-current-mtp-warning-modal to enable navigation page
        this.isStateEditModalActive = false;

        this.mtpData = await this.MtpService.getMtpData(id, this.mtpYear, this.miniFdYear);
        this.initialMtpData = {
            ...this.mtpData,
        };

        this.mtpData.modified = moment(Helper.UtcToLocalTime(this.mtpData.modified)).format('DD/MM/YYYY - HH:mm');
        if (this.mtpData.reviewComment) {
            this.formatDateReview = moment(Helper.UtcToLocalTime(this.mtpData.reviewComment.date)).format('DD/MM/YYYY');
        }
        if (this.mtpData.startDate) {
            this.mtpData.startDate = moment(Helper.UtcToLocalTime(this.mtpData.startDate)).format('DD/MM/YYYY');
        }
        if (this.mtpData.endDate) {
            this.mtpData.endDate = moment(Helper.UtcToLocalTime(this.mtpData.endDate)).format('DD/MM/YYYY');
        }

        // Get external data to populate external
        this.clearExternalData();
        if (this.mtpData.riskOpportunityId && this.mtpData.riskOpportunityId.trim().length > 0) {
            this.getExternalDataForDetails();
        }

        this.selectedMtpStatus = this.data.constants.mtpStatus[this.mtpData.mtpStatus];
        this.selectedProjectStatus = this.mtpData.projectStatus;
        this.selectedFundingStatus = this.mtpData.fundingStatus;
        this.selectedMtpYear = `${this.mtpYear}-${this.miniFdYear}`;
        this.loading = false;
    }

    private getIdInt(obj, obj2) {
        let i: number = 1;
        let j = null;
        Object.values(obj2).forEach(el => {
            if (obj === el) {
                j = i;
            }
            i++;
        });
        return j;
    }

    private goToReview() {
        this.reviewSection = true;
        this.validateReviewComment = true;
        this.flagsArray = Object.entries(this.data.constants.flags).map(([key, title]) => ({ key, title }));
        this.allFlags = this.flagsArray.slice(0);
        this.otherFlagsArray = this.flagsArray.slice(0);
        this.selectedOtherFlags = [];
        this.selectedMainFlag = [];
        this.reviewBudgetRequestComment = this.mtpData.proposedFlagMessage;
        if (this.mtpData.mainFlag === 0) {
            this.selectedMainFlag = [];
        } else {
            const singleObj = {};
            singleObj['key'] = this.mtpData.mainFlag.toString();
            singleObj['title'] = this.data.constants.flags[parseInt(this.mtpData.mainFlag)];
            this.selectedMainFlag.push(singleObj);
            this.disableFromOtherFlags();

        }

        if (this.selectedMainFlag === null || this.selectedMainFlag === undefined) {
            this.selectedMainFlag = null;
        }

        if (this.mtpData && this.data) {
            const listOfObjects = [];
            this.mtpData.otherFlags.forEach((entry) => {
                const oFlags = {};
                oFlags['key'] = entry.toString();
                oFlags['title'] = this.data.constants.flags[parseInt(entry)];
                listOfObjects.push(oFlags);
            });
            this.selectedOtherFlags = listOfObjects;
            this.disableFromFlags();
        }

        this.checkempty();
    }

    private disableFromFlags() {
        if (this.selectedOtherFlags) {
            const flags = [];
            this.allFlags.forEach((el) => {
                const flag = this.selectedOtherFlags.find((f) => f.key === el.key);
                if (!flag) {
                    flags.push(el);
                }
            });
            this.flagsArray = flags;

        }
    }

    private disableFromOtherFlags() {
        if (this.selectedMainFlag.length !== 0) {
            this.otherFlagsArray = this.allFlags.filter(key => key.key !== this.selectedMainFlag[0].key);
            this.reviewBudgetRequest.mainFlag = parseInt(this.selectedMainFlag[0].key);
        } else {
            this.otherFlagsArray = this.allFlags.slice(0);
            this.reviewBudgetRequest.mainFlag = 0;
        }

        // Set validateMainFlag to disable/enable otherFlags multiselect and clear OtherFlags if mainFlag is emtpy
        if (this.selectedMainFlag.length === 0) {
            this.validateMainFlag = true;
            this.flagsArray = Object.entries(this.data.constants.flags).map(([key, title]) => ({ key, title }));
            this.selectedOtherFlags = [];
        } else {
            this.validateMainFlag = false;
        }
    }

    private cancelReview() {
        this.reviewSection = false;
        this.selectedOtherFlags = [];
        this.validateMainFlag = true;
    }

    private async loadReview() {
        this.loading = true;
        this.reviewBudgetRequest.otherFlags = [];
        if (this.selectedOtherFlags.length === 0 && this.selectedMainFlag.length === 0) {
            this.changeMtpStatus.mtpStatus = 4;
            this.selectedMtpStatus = this.data.constants.mtpStatus[this.changeMtpStatus.mtpStatus];
        } else {
            this.changeMtpStatus.mtpStatus = 3;
            this.selectedMtpStatus = this.data.constants.mtpStatus[this.changeMtpStatus.mtpStatus];
        }
        this.validateReview();
        if (!this.validateReviewComment) {
            this.reviewBudgetRequest.otherFlags = [];
            this.reviewBudgetRequest.mtpYear = this.mtpYear;
            this.reviewBudgetRequest.mtpStatus = this.selectedMtpStatus.id;
            if (this.selectedOtherFlags) {
                this.selectedOtherFlags.forEach((e) => {
                    this.reviewBudgetRequest.otherFlags.push(parseInt(e.key));
                });
            } else {
                this.reviewBudgetRequest.otherFlags = [];
            }
            if (this.selectedMainFlag.length !== 0) {
                this.reviewBudgetRequest.mainFlag = parseInt(this.selectedMainFlag[0].key);
            } else {
                this.reviewBudgetRequest.mainFlag = 0;
            }
            this.reviewBudgetRequest.budgetRequestId = this.detailsBudgetRequests.id;
            this.reviewBudgetRequest.comment.text = this.reviewBudgetRequestComment;

            try {
                await this.BudgetRequestsService.reviewBudgetRequest(this.reviewBudgetRequest);
                this.reInitMtp(this.mtpData.year, this.detailsBudgetRequests.id);
                this.reviewSection = false;
            } catch (error) {
                this.$bvToast.show('toast-exception');
            } finally {
                this.loading = false;
            }
        }
    }

    private async submitReview() {
        if (this.selectedOtherFlags.length === 0 && this.selectedMainFlag.length === 0) {
            $('#mtpStatusReviewedModal').modal('show');
            this.isReviewedModalActive = true;
        } else {
            $('#mtpStatusFlaggedModal').modal('show');
            this.isFlaggedModalActive = true;
        }
    }

    private validateReview() {
        if (this.reviewBudgetRequestComment === '' || this.reviewBudgetRequestComment === null || this.reviewBudgetRequestComment.trim().length === 0) {
            this.validateReviewComment = true;
        } else {
            this.validateReviewComment = false;
        }
    }

    private checkempty() {
        this.validateReview();
    }

    private async changeMtp() {
        await this.BudgetRequestsService.changeMtpStatus(this.changeMtpStatus);
    }

    private async changeToDraft(selected?: any) {
        this.changeMtpStatus.globalIds.push(this.detailsBudgetRequests.globaId);
        this.changeMtpStatus.mtpStatus = selected['id'];
        this.changeMtpStatus.mtpYear = this.mtpData.year;
        this.data.changedMtpLoading = true;
        await this.changeMtp();
        this.detailsBudgetRequests.planning.mtpStatus = selected['id'];
        this.mtpData.mtpStatus = selected['id'];
        this.data.changedMtpLoading = false;
        this.selectedMtpStatus = this.mtpStatus[selected['id'] - 1];

    }

    private removeDuplicateIds(originalArray) {
        let newArray = [];
        let lookupObject = {};
        let i;
        for (i in originalArray) {
            lookupObject[originalArray[i].currency.code] = originalArray[i];
        }
        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    private forceFileDownload(name, data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    private async exportToExcel() {
        this.exportBudgetRequests.globalIds = [];
        this.exportBudgetRequests.year = this.mtpData.year;
        this.exportBudgetRequests.miniFdYear = this.miniFdYear;
        this.exportBudgetRequests.globalIds.push(this.detailsBudgetRequests.globalId);
        this.data.exportBr = true;
        const content = await this.ExportBudgetRequestService.exportBudgetRequests(this.exportBudgetRequests);
        this.forceFileDownload(content.name, content.content);
        this.data.exportBr = false;
    }

    private initialDataForRemoveBr() {
        this.deleteBudgetRequests.globalIds = [];
        this.deleteBudgetRequests.globalIds.push(this.detailsBudgetRequests.globalId);
        this.setToInactiveBr.globalIds = [];
        this.setToInactiveBr.globalIds.push(this.detailsBudgetRequests.globalId);
    }

    private async deleteBudgetRequest() {
        (this.$refs['remove-br-modal'] as any).clear();
        if ((this.mtpData.mtpStatus === 1 || this.mtpData.mtpStatus === 2) && this.detailsBudgetRequests.isActive) {
            this.createdYear = new Date(this.detailsBudgetRequests.created).getFullYear();
            $('#removeBrModal').modal('show');
            this.isStateEditModalActive = true;
            this.reviewSection = false;
        } else {
            $('#setInactiveModal').modal('show');
            this.isStateEditModalActive = true;
            this.reviewSection = false;
        }
        this.initialDataForRemoveBr();
    }

    private async removeBr() {

        if (this.detailsBudgetRequests.isActive) {
            this.data.removeFromMtp = true;
            this.deleteBudgetRequests.mtpYear = this.mtpData.year;
        } else {
            this.data.deleteBr = true;
            this.deleteBudgetRequests.mtpYear = this.mtpData.year;
        }
        this.deleteBudgetRequests.isActive = this.detailsBudgetRequests.isActive;
        await this.BudgetRequestsService.deleteBudgetRequest(this.deleteBudgetRequests);
        this.$router.push('budget-requests');
        this.data.removeFromMtp = false;
        this.data.deleteBr = false;
        this.$bvToast.show('example-toast');
    }

    private async setBudgetRequestsInactive() {
        this.data.removeFromMtp = true;
        if (this.detailsBudgetRequests.isActive) {
            this.setToInactiveBr.mtpYear = this.mtpData.year;
        } else {
            this.setToInactiveBr.mtpYear = null;
        }
        await this.BudgetRequestsService.setBudgetRequestsInactive(this.setToInactiveBr);
        localStorage.setItem('selectedMtp', '0');
        this.$router.push('/budget-requests');
        this.data.removeFromMtp = false;
        this.$bvToast.show('inactive');
    }

    private goToBrMtpYear(year) {
        localStorage.setItem('selectedMtp', year);
        this.$router.push('/budget-requests');
    }

    private deleteBrInactive() {
        if (this.mtpData.mtpStatus === 1 || this.mtpData.mtpStatus === 2) {
            $('#confirmDeleteModal').modal('show');
            this.isStateEditModalActive = true;
            this.initialDataForRemoveBr();
        }
    }

    private beforeChangeMtpStatus(selectedMtpStatus) {
        this.reviewSection = false;
        this.idOfSelectedMtpStatus = selectedMtpStatus;
        switch (selectedMtpStatus.name) {
            case 'Draft':
                $('#updateMtpModal').modal('show');
                this.isStateEditModalActive = true;
                break;
            case 'Submitted':
                this.changeToDraft(selectedMtpStatus);
                break;
            case 'Approved':
                this.changeToDraft(selectedMtpStatus);
                break;
        }
    }

    private async changeFundingStatus(selectedFundingStatus) {
        this.loading = true;
        let changeFundingStatus = {
            globalIds: [this.detailsBudgetRequests.globalId],
            mtpYear: parseInt(this.mtpYear),
            fundingStatus: parseInt(selectedFundingStatus)
        };

        if (parseInt(selectedFundingStatus) !== this.selectedFundingStatus) {
            try {
                await this.BudgetRequestsService.releaseBudgetRequests(changeFundingStatus);
                this.selectedFundingStatus = parseInt(selectedFundingStatus);
                this.mtpData.fundingStatus = parseInt(selectedFundingStatus);
                this.$bvToast.show('funding-status-toast');
            } catch (error) {
                this.$bvToast.show('toast-exception');
            }
        }

        this.loading = false;
    }

    private get projectStatusDisabled(): boolean {
        return (
            !this.userPermissions.includes('BudgetRequestDetailsEdit') ||
            !(this.data.constants.mtpStatus[this.mtpData.mtpStatus]?.name === 'Approved' || this.data.constants.mtpStatus[this.mtpData.mtpStatus]?.name === 'Reviewed' || this.data.constants.mtpStatus[this.mtpData.mtpStatus]?.name === 'Flagged') ||
            !(this.isMtpPeriod || this.isLastActivePeriod)
        )
    }

    private async changeProjectStatus(selectedProjectStatus) {
        this.loading = true;
        let editProjectStatusPayload = {
            globalIds: [this.detailsBudgetRequests.globalId],
            mtpYear: parseInt(this.mtpYear),
            projectStatus: parseInt(selectedProjectStatus)
        } as EditProjectStatusPayload;

        if (parseInt(selectedProjectStatus) !== this.selectedProjectStatus) {
            try {
                await this.BudgetRequestsService.editProjectStatus(editProjectStatusPayload);
                this.selectedProjectStatus = parseInt(selectedProjectStatus);
                this.mtpData.projectStatus = parseInt(selectedProjectStatus);
                this.$bvToast.show('project-status-toast');
            } catch (error) {
                this.$bvToast.show('toast-exception');
            }
        }

        this.loading = false;
    }

    private onClickCancel(value) {
        this.isStateEditModalActive = value;
        this.isFlaggedModalActive = value;
        this.isReviewedModalActive = value;
        this.isAccessDeniedModalActive = value;
    }

    private async addToCurrentMtp() {
        if (!this.detailsBudgetRequests.mtpList.find(y => y === this.currentYear) || !this.detailsBudgetRequests.isActive) {
            let miniFdYear: number | undefined = undefined;

            if (this.detailsBudgetRequests.planning.hasChangeRequest && this.detailsBudgetRequests.mtpList.length === 0) {
                miniFdYear = this.detailsBudgetRequests.planning.miniFdYear;
            }

            this.$router.push({
                path: 'budget-request',
                query: { year: this.mtpData.year, globalId: this.detailsBudgetRequests.globalId, miniFdYear: miniFdYear?.toString() }
            });
        } else {
            this.budgetRequestId = this.detailsBudgetRequests.id;
            $('#moveCurrentMtpWarningModal').modal('show');
            this.isStateEditModalActive = true;
        }
    }

    private hideToast() {
        this.$bvToast.hide('toast-exception');
        this.$bvToast.hide('funding-status-toast');
        this.$bvToast.hide('project-status-toast');
        this.$bvToast.hide('edit-controlling-toast');
    }

    private sortExternalData(data: any, sortOrder: any) {
        data.sort(function (a, b) {
            let A = a[Object.keys(a)[0]], B = b[Object.keys(b)[0]];
            if (sortOrder.indexOf(A) > sortOrder.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    private formatDates(externalData) {
        if (externalData.nextPlannedStartDate !== null && externalData.nextPlannedStartDate)
            externalData.nextPlannedStartDate = format(new Date(externalData.nextPlannedStartDate), 'dd/MM/yyyy');

        if (externalData.lastMaintenanceDate !== null && externalData.lastMaintenanceDate)
            externalData.lastMaintenanceDate = format(new Date(externalData.lastMaintenanceDate), 'dd/MM/yyyy');

        if (externalData.maintenancePlanning !== null) {
            externalData.maintenancePlanning.forEach((a) => {
                if (a.planningDate !== null) {
                    a.planningDate = format(new Date(a.planningDate), 'dd/MM/yyyy');
                }
            });
        }
    }

    private async duplicateBudgetRequest() {
        if (!this.reviewSection) {
            this.$router.push({
                path: 'budget-request',
                query: { year: this.selectedMtpYear, globalId: this.detailsBudgetRequests.globalId, isDuplicate: '1' }
            });
        } else {
            $('#confirmationReviewModal').modal('show');
            EventBus.$on(EventBus.REVIEW_CHANGES.DISCARD_CHANGES_MODAL, () => {
                this.reviewSection = false;
                $('#confirmationReviewModal').modal('hide');
                this.$router.push({
                    path: 'budget-request',
                    query: {
                        year: this.selectedMtpYear,
                        globalId: this.detailsBudgetRequests.globalId,
                        isDuplicate: '1'
                    }
                });
            });
        }
    }

    private editControllingInformation() {
        $('#editControllingModal').modal('show');
        this.$bvToast.hide('edit-controlling-toast');
        let dataEditControlling = {
            budgetRequestId: this.detailsBudgetRequests.id,
            globalId: this.detailsBudgetRequests.globalId,
            year: this.detailsBudgetRequests.planning.year,
            mtpStatus: this.selectedMtpStatus.id,
            wbsElement: this.mtpData.wbsElement,
            outageType: this.mtpData.outageType,
            outageId: this.mtpData.outageId,
            comment: this.mtpData.comment,
            planningAndControllingClassification: this.mtpData.planningAndControllingClassification,
            startDate: this.initialMtpData.startDate,
            hasPlanningCosts: this.mtpData.hasPlanningCosts,
        };

        (this.$refs['edit-controlling'] as any).setDataEditControlling(dataEditControlling);
    }

    private getDataEditControlling(data) {
        this.mtpData.wbsElement = data.wbsElement;
        this.mtpData.comment = data.comment;
        this.mtpData.outageId = data.outageId;
        this.mtpData.startDate = moment(Helper.UtcToLocalTime(data.startDate)).format('DD/MM/YYYY');
        this.initialMtpData.startDate = data.startDate;
        this.mtpData.hasPlanningCosts = data.hasPlanningCosts;
        this.$bvToast.show('edit-controlling-toast');
    }

    private get disableProjectPrioritization(): boolean {
        return this.mtpYear !== this.currentYear || this.projectPrioritizationLoading;
    }

    private async runProjectPrioritization(): Promise<void> {
        this.projectPrioritizationLoading = true;
        const content = await this.AdminService.exportProjectPrioritization([]);

        if (content) {
            this.reviewSection = false;
            await this.reInitMtp(this.mtpData.year, this.detailsBudgetRequests.id);
            this.$pui.toast({
                type: 'success',
                title: 'Success',
                copy: 'Project Prioritization successfully run.'
            });
        } else {
            this.$pui.toast({
                type: 'error',
                title: 'Error',
                copy: 'Project Prioritization failed.'
            });
        }

        this.projectPrioritizationLoading = false;
    }
}
