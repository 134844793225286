import { endOfYear, addMonths, isAfter } from "date-fns"
import { Permission } from "./permission"

const MTP_EXTENSION_PERIOD_IN_MONTHS = 3

export function isExtendedCurrentMtp(mtpYear: number, permissions: string[] = []): boolean {
    const mtpYearEnd = endOfYear(new Date(mtpYear, 0, 1))
    const extendedMtpYearEnd = addMonths(mtpYearEnd, MTP_EXTENSION_PERIOD_IN_MONTHS)
    const currentDate = new Date()

    const isAdmin = permissions.includes(Permission.MTP_PERIODS_WRITE)
    
    return !isAfter(currentDate, extendedMtpYearEnd) && isAdmin
}